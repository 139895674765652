<template>
	<div>
		<el-row :gutter="20" type="flex" class="row-bg" justify="space-between"
			style="padding-top: 10px;padding-bottom: 10px;">
			<el-col :span="5"
				style="background-color: rgb(255, 255, 255); border-radius: 8px;padding: 15px;margin-right: 1%; max-width: 19.833333%">
				<div>
					<el-select size="small" style="width: 100%" v-if="isAdmin" @change="agentChange"
						v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mb10">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<el-select size="small" style="width: 100%" v-if="isAgent" v-model="query.companyId"
						@change="fileChange" filterable clearable placeholder="企业" v-show="isNormal"
						class="handle-select">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
					<el-input size="small" v-model="query.name" placeholder="请输入线索名" style="width: 100%"
						class="handle-input mr10 mb10 mt10"></el-input>

					<div style="display: flex;flex-direction: column;justify-content: space-between;">
						<el-button size="small" type="primary" style="width: 100%;" icon="el-icon-search"
							@click="getcustomerFile">搜索</el-button>
						<el-button size="small" type="primary" style="width: 100%;margin-left: 0;margin-top: 10px;"
							icon="el-icon-delete" @click="getDeleteCompletedFile">
							删除已完成文件
						</el-button>
					</div>
				</div>
				<div class="tree" id="my">
					<ul>
						<li class="item-menuList" v-for="item in menuList" :key="item.id">
							<div class="title" @click="curShow(item)" :class="{ active1: item.flag == true }">
								<span class="title-name">{{ item.fileName }}</span>
								<span v-if="item.flag" style="float: right">
									<i class="el-icon-edit ml10" v-show="isNormal"
										@click.stop="getEdit(item.id, item.fileName)"></i>
									<i class="el-icon-delete ml10 red" @click.stop="getDeleteCustomerFile(item.id)"></i>
								</span>
								<el-progress style="width: 85%;margin: 10px 0;"
									:percentage="(item.jd * 100).toFixed(2)"></el-progress>
								<span style="color:#515a6e;">导入时间:{{ item.createdAt }}</span>
							</div>
							<ul class="p-item" v-show="item.flag">
								<li v-for="items in item.children" :key="items.id" @click="getCustomers(items.id)"
									:class="{ blur: items.id == num }">
									<span class="icon1"></span>
									<span class="icon"></span>
									<div class="p-title">{{ items.name }}</div>
									<el-progress style="width: 85%;margin: 10px 0;"
										:percentage="(items.jd * 100).toFixed(2)"></el-progress>
								</li>
							</ul>
						</li>
					</ul>
					<div class="pagination">
						<el-pagination small layout="prev, pager, next" @size-change="handleSizeChangemenu"
							@current-change="handleCurrentChangemenu" :page-size="menu.pageSize" :pager-count="5"
							v-loading="loading" :total="menupageTotal"></el-pagination>
					</div>
				</div>
				<el-dialog title="编辑名称" v-model="editName" width="450px" @close="closeDialog('form')"
					:close-on-click-modal="false">
					<el-form :model="editNameForm" :rules="rules" ref="form" label-width="100px">
						<el-form-item label="资料名称" prop="fileName"><el-input size="small"
								v-model="editNameForm.fileName"></el-input></el-form-item>
						<el-form-item>
							<el-button size="small" @click="editName = false">取消</el-button>
							<el-button size="small" type="primary"
								@click="getUpdateCustomerFile('editNameForm')">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</el-col>
			<el-col :span="19"
				style="padding-left: 10px;padding-right: 10px;background-color: rgb(255, 255, 255);border-radius: 8px;">
				<el-tabs v-model="activeName" @tab-click="handleClick" style="padding: 10px 0 0 15px;">
					<el-tab-pane label="号码清单" name="numberList">
						<div class="container">
							<div class="handle-box">
								<el-select size="small" v-model="query.departmentId" @change="departmentChange"
									filterable clearable v-show="isNormal" placeholder="部门" class="handle-select mr10"
									style="width: 180px;">
									<el-option v-for="item in departments" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
								<el-select size="small" v-model="query.userId" filterable clearable v-show="isNormal"
									placeholder="员工" class="handle-select mr10" style="width: 180px">
									<el-option v-for="item in users" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
								<el-select size="small" v-model="query.status" filterable placeholder="状态"
									style="width: 116px" class="handle-select mr10">
									<el-option v-for="item in recordStatus" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
								<el-input size="small" v-model="query.phone" placeholder="客户联系方式" style="width: 120px"
									class="handle-input mr10"></el-input>
								<el-button size="small" type="primary" icon="el-icon-search"
									@click="handleSearch">搜索</el-button>
								<el-button size="small" type="primary" icon="el-icon-upload" class="handle-del ml10"
									@click="uploadExcel">导入线索</el-button>
								<el-button size="small" type="primary" icon="el-icon-download" class="handle-del ml10"
									@click="getdownloadCustomerTemplate">下载模板</el-button>
								<!-- 	<a :href="`${path}customer.xlsx`" class="down ml10 mt10" download="客户模板.xlsx">
									<i class="el-icon-download"></i>
									下载模板
								</a> -->
								<el-button size="small" type="primary" icon="el-icon-add  el-icon--right"
									class="handle-del ml10  mt10" @click="batchDel">批量删除</el-button>
								<!-- <el-button size="small" type="primary" icon="el-icon-add  el-icon--right" class="handle-del ml10  mt10" @click="getWebAddress">{{ AppBtn }}</el-button> -->
								<el-button size="small" type="primary" icon="el-icon-add  el-icon--right"
									class="handle-del ml10  mt10" @click="call" v-if="deviceName !== null">
									自动拨号
								</el-button>
								<el-button size="small" type="primary" icon="el-icon-add  el-icon--right"
									class="handle-del ml10  mt10" @click="getshoudongbohao" v-if="deviceName !== null">
									手动拨号
								</el-button>
								<el-button size="small" type="primary" icon="el-icon-add  el-icon--right"
									class="handle-del ml10  mt10" @click="setIntervalsBox" v-if="deviceName !== null">
									间隔设置
								</el-button>
							</div>

							<el-table :data="tableData" border class="table" ref="multipleTable"
								header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }"
								@selection-change="handleSelectionChange" :row-style="{ height: '20px' }"
								:cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
								<el-table-column type="selection" width="55" align="center"></el-table-column>
								<el-table-column v-if="show" prop="id" label="customerId"
									align="center"></el-table-column>
								<el-table-column prop="contactName" label="客户姓名" align="center"></el-table-column>
								<el-table-column prop="phoneNumber" label="联系电话" align="center">
									<template #default="scope" v-if="desensitization == true">
										{{ scope.row.phoneNumber ? scope.row.phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3') : '' }}
									</template>
								</el-table-column>
								<el-table-column prop="status" label="状态" align="center">
									<template #default="scope">
										<span v-if="scope.row.status == '未拨打'" style="color: #f56c6c;">未拨打</span>
										<span v-if="scope.row.status == '已拨打'" style="color: #4f7afd;">已拨打</span>
									</template>
								</el-table-column>
								<el-table-column prop="userName" label="分配员工" align="center"></el-table-column>
								<el-table-column prop="remark" label="备注" align="center"></el-table-column>
								<el-table-column label="操作" width="240" align="center">
									<template #default="scope">
										<div style="display: inline-block;margin-right: 16px;"
											v-if="deviceName !== null">
											<el-button type="primary" plain size="mini"
												@click="handleSocketSend(scope.row)" :disabled="bodadisabled"
												v-if="scope.row.status == '未拨打'">
												拨打
											</el-button>
											<el-button type="primary" plain size="mini" disabled v-else>拨打</el-button>
										</div>
										<el-button size="small" type="text" icon="el-icon-edit"
											@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
										<el-button size="small" type="text" icon="el-icon-delete"
											style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row.id)">
											删除
										</el-button>
									</template>
								</el-table-column>
							</el-table>

							<div class="pagination">
								<el-pagination background layout="total, sizes, prev, pager, next, jumper"
									:current-page="query2.pageIndex" :page-sizes="[100, 500, 1000, 2000]"
									:page-size="query2.pageSize" :total="pageTotal" @size-change="handleSizeChange"
									@current-change="handlePageChange"></el-pagination>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="线索统计" name="clueEcharts">
						<el-row :gutter="20" type="flex" class="row-bg statistics-box" justify="space-between">
							<el-col :span="8">
								<div class="list">
									<div class="info">
										<div class="tit">号码总量</div>
										<div class="num">{{ statisticsData.total || 0 }}</div>
									</div>
									<div class="progress-box">
										<el-progress type="circle" :percentage="statisticsData.call">
											<template #default="{ percentage }">
												<span class="percentage-value">{{ percentage.toFixed(2) }}%</span>
												<span class="percentage-label">已拨打</span>
											</template>
										</el-progress>
									</div>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="list">
									<div class="info">
										<div class="tit">意向客户</div>
										<div class="num">{{ statisticsData.intention || 0 }}</div>
									</div>
									<div class="progress-box">
										<el-progress type="circle" :percentage="statisticsData.intentionOdds">
											<template #default="{ percentage }">
												<span class="percentage-value">{{ percentage.toFixed(2) }}%</span>
												<span class="percentage-label">意向率</span>
											</template>
										</el-progress>
									</div>
								</div>
							</el-col>
							<el-col :span="8" type="flex">
								<div class="list">
									<div class="info">
										<div class="tit">已拨打</div>
										<div class="num">{{ statisticsData.haveToCall || 0 }}</div>
									</div>
									<div class="progress-box">
										<el-progress type="circle" :percentage="statisticsData.connectOdds">
											<template #default="{ percentage }">
												<span class="percentage-value">{{ percentage.toFixed(2) }}%</span>
												<span class="percentage-label">接通率</span>
											</template>
										</el-progress>
									</div>
								</div>
							</el-col>
						</el-row>

						<div id="statisticsEcharts" style="height: 550px;width: 100%;"></div>
					</el-tab-pane>
				</el-tabs>
				<div class="clue">
					<el-dialog title="导入线索" v-model="upload" width="650px" :close-on-click-modal="false">
						<el-upload ref="upload" action="/api/customer/upload" :headers="token" :limit="1"
							:on-success="uploadSuccess" :on-preview="handlePreview" :on-remove="handleRemove"
							:auto-upload="false" :data="uploadData">
							<el-button size="small" type="primary">选择文件</el-button>
							<div class="el-upload__tip">1、只能上传Excel格式文件</div>
							<div class="el-upload__tip">2、文件号码可以分配多个员工</div>
						</el-upload>
						<!-- 	<div v-if="isrepeat" style="margin: 10px 0;">
							<span style="margin-right: 6px;color: #606266;font-weight: 600;display: inline-block;width: 50px;">{{ repeatText }}:</span>
							<el-switch v-model="form.repeat" active-color="#13ce66" inactive-color="#ff4949" @change="getswitch"></el-switch>
						</div> -->
						<div v-if="isNormal" style="margin: 10px 0;">
							<span
								style="margin-right: 6px;color: #606266;font-weight: 600;display: inline-block;width: 50px;">部&nbsp;&nbsp;门:</span>
							<el-select size="small" v-model="query.departmentId" @change="departmentChange2" filterable
								clearable v-show="isNormal" placeholder="部门" class="handle-select mr10"
								style="width: 180px;">
								<el-option v-for="item in departments" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</div>
						<div v-if="nameList.length > 0" style="max-height: 400px;overflow: auto;">
							<h3 style="margin: 10px 0;">可分配员工</h3>
							<el-checkbox-group v-model="form.nameList" @change="handleCheckedCitiesChange">
								<el-checkbox v-for="item in nameList" :label="item.label" :key="item.value" border
									style="width: 30%;margin:0;margin-bottom:10px;margin-right: 1.5%;">
									{{ item.value }}
								</el-checkbox>
							</el-checkbox-group>
						</div>
						<h3 style="margin: 10px 0;">线索分配规则:</h3>
						<div>号码平均分配给选中的员工。</div>
						<div>例如：100条线索分配2名员工,每位员工则分配50条线索。100条线索分配3名员工,第1位,第2位员工每人分配33条线索,第3位分配34条线索。</div>

						<el-button size="small" class="btn" type="success" v-loading.fullscreen.lock="loading"
							@click="submitUpload">确定上传</el-button>
					</el-dialog>
				</div>
				<el-dialog title="编辑客户" v-model="addVisible" width="450px" @close="closeDialog('form')"
					:close-on-click-modal="false">
					<el-form :model="form" :rules="rules" ref="form" label-width="100px">
						<el-form-item label="姓名" prop="contactName"><el-input size="small"
								v-model="form.contactName"></el-input></el-form-item>
						<el-form-item label="联系方式" prop="phoneNumber"><el-input size="small"
								v-model="form.phoneNumber"></el-input></el-form-item>

						<el-form-item v-if="form.attribute1" :label="form.attribute1.label"
							:prop="form.attribute1.value">
							<el-input size="small" v-model="form.attribute1.value"></el-input>
						</el-form-item>
						<el-form-item v-if="form.attribute2" :label="form.attribute2.label"
							:prop="form.attribute2.value">
							<el-input size="small" v-model="form.attribute2.value"></el-input>
						</el-form-item>
						<el-form-item v-if="form.attribute3" :label="form.attribute3.label"
							:prop="form.attribute3.value">
							<el-input size="small" v-model="form.attribute3.value"></el-input>
						</el-form-item>
						<el-form-item v-if="form.attribute4" :label="form.attribute4.label"
							:prop="form.attribute4.value">
							<el-input size="small" v-model="form.attribute4.value"></el-input>
						</el-form-item>
						<el-form-item v-if="form.attribute5" :label="form.attribute5.label"
							:prop="form.attribute5.value">
							<el-input size="small" v-model="form.attribute5.value"></el-input>
						</el-form-item>
						<el-form-item label="跟进员工" prop="userId">
							<el-select size="small" v-model="form.userId" placeholder="请选择跟进员工" :disabled="userDis"
								style="width:100%">
								<el-option v-for="item in users" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="备注"><el-input size="small" type="textarea"
								v-model="form.remark"></el-input></el-form-item>
						<el-form-item>
							<el-button size="small" style="margin-left: 50%;" @click="addVisible = false">取消</el-button>
							<el-button size="small" v-if="addButton" type="primary"
								@click="addCustomer('form')">确定</el-button>
							<el-button size="small" v-if="updateButton" type="primary"
								@click="updateCustomer('form')">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</el-col>
		</el-row>

		<el-dialog v-model="dialogIntention" width="650px" :show-close="false" class="dialogIntention"
			:close-on-click-modal="false">
			<div slot="title" class="dialog-title">
				<!--  :title="formIntentionPhoneNumber"  -->
				<i class="el-icon-s-custom"></i>
				<div class="title" v-if="callStatus == false">正在与{{ phoneNumber }}通话中...</div>
				<div class="title" v-else>与{{ phoneNumber }}通话结束</div>
				<div class="time">通话时长&nbsp;{{ formatSeconds(callDuration) }}</div>
			</div>
			<el-form :model="formIntention" ref="form" label-width="100px">
				<el-form-item label="客户姓名" prop="name"><el-input size="small"
						v-model="formIntention.name"></el-input></el-form-item>
				<el-form-item label="意向选择" prop="intention">
					<el-radio-group v-model="formIntention.intention">
						<el-radio v-for="item in optionsIntentionList" :key="item.value" :label="item.value"
							:value="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- <el-form-item label="自定义标签">
					<el-select size="small" v-model="form.userId" placeholder="请选择标签" :disabled="userDis" style="width:100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="备注">
					<el-input size="small" type="textarea" v-model="formIntention.remark" placeholder="请填写该客户通话备注"
						:autosize="{ minRows: 4, maxRows: 8 }"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;"
					v-if="zidongbohao == false">
					<el-button type="primary" size="small" @click="getSocketIntention(1)">转入CRM</el-button>
					<el-button type="primary" size="small" @click="getblack">加入黑名单</el-button>
					<el-button type="primary" size="small" @click="socketForced">强制结束</el-button>
					<el-button type="primary" size="small" :disabled="bodadisabled"
						@click="getSocketIntention">确定</el-button>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;"
					v-if="zidongbohao == true">
					<div v-if="tableData[arrI]">下一个客户姓名:{{ tableData[arrI].contactName || '无' }}</div>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;"
					v-if="zidongbohao == true">
					<div v-if="tableData[arrI]">下一个客户电话:{{ tableData[arrI].phoneNumber || '无' }}</div>
				</el-form-item>
				<div style="display: flex;flex-direction: row;justify-content: end;margin:0;"
					v-if="zidongbohao == true">
					<el-button type="primary" size="small" @click="getSocketIntentions(1)">转入CRM</el-button>
					<el-button type="primary" size="small" @click="getblack">加入黑名单</el-button>
					<el-button type="primary" size="small" @click="socketForced">强制结束</el-button>
					<el-button type="primary" size="small" @click="getout">退出自动拨号</el-button>
					<el-button type="primary" size="small" @click="getSocketIntentions" :disabled="bodadisabled">
						拨打下一个
						<span v-if="setSeconds > 0">（{{ setSeconds }}s）</span>
					</el-button>
				</div>
			</el-form>
		</el-dialog>

		<el-dialog v-model="manualDialing" title="键盘手动拨号" width="450px" :close-on-click-modal="false">
			<el-tabs v-model="manualDialingTab" @tab-click="handleManualDialingTab" style="padding: 10px 0 0 15px;"
				class="aaa">
				<el-tab-pane label="拨号键盘" name="dial">
					<div class="dial-box">
						<div class="input-box"><el-input size="small" placeholder="请输入号码" v-model="phone" clearable
								@change="addPhone2"></el-input></div>
						<div class="dial_list" @click="addPhone('1')">1</div>
						<div class="dial_list" @click="addPhone('2')">2</div>
						<div class="dial_list" @click="addPhone('3')">3</div>
						<div class="dial_list" @click="addPhone('4')">4</div>
						<div class="dial_list" @click="addPhone('5')">5</div>
						<div class="dial_list" @click="addPhone('6')">6</div>
						<div class="dial_list" @click="addPhone('7')">7</div>
						<div class="dial_list" @click="addPhone('8')">8</div>
						<div class="dial_list" @click="addPhone('9')">9</div>
						<div class="dial_list" @click="addPhone('#')">#</div>
						<div class="dial_list" @click="addPhone('0')">0</div>
						<div class="dial_list" @click="addPhone('*')">*</div>
						<div class="dial-btn" style="width: 200;height: 40px;display: block;">
							<el-button size="small" type="primary" icon="el-icon-phone" :disabled="bodadisabled"
								@click="autoDial2">拨打</el-button>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="最近记录" name="record">
					<div class="record-box">
						<ul>
							<li v-for="item in speedsList" :key="item.id">
								<div class="phone">{{ item.phoneNumber }}</div>
								<div class="info">
									<div class="address">{{ item.phoneModel }}</div>
									<div class="call-time">外呼时间：{{ toDateTimeStr(item.callDate) }}</div>
								</div>
								<div class="status-box">
									<div class="call-status" v-if="item.callStatus == '未接通'"
										style="background-color: #f56c6c;">{{ item.callStatus }}</div>
									<div class="call-status" v-if="item.callStatus == '已接通'"
										style="background-color: #22d422;">{{ item.callStatus }}</div>
									<div class="intention" v-if="item.intention == 'A: 意向强'"
										style="background-color: #10B8FA;">{{ item.intention }}</div>
									<div class="intention" v-if="item.intention == 'B: 意向一般'"
										style="background-color: #22d422;">{{ item.intention }}</div>
									<div class="intention" v-if="item.intention == 'C: 无意向'"
										style="background-color: #FAC858;">{{ item.intention }}</div>
									<div class="intention" v-if="item.intention == 'D: 需再跟进'"
										style="background-color: #9A60B4;">{{ item.intention }}</div>
									<div class="intention" v-if="item.intention == 'E: 无需跟进'"
										style="background-color: #73C0DE;">{{ item.intention }}</div>
									<div class="intention" v-if="item.intention == '风险号码'"
										style="color: #fff;background:#ff0000;">{{ item.intention }}</div>
									<div class="call-duration">{{ item.callDuration }}</div>
								</div>
								<div class="remark">备注：{{ item.remark }}</div>
							</li>
						</ul>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<el-dialog v-model="callPopup5" title="黑名单号码" width="450px" :close-on-click-modal="false">
			<div class="call-type5">
				<div class="black-radio">
					<div class="title">原因</div>
					<el-radio-group v-model="blackremark" @change="blackRadioChange">
						<el-radio label="明确表示不再允许打电话">明确表示不再允许打电话</el-radio>
						<el-radio label="有明显投诉倾向">有明显投诉倾向</el-radio>
						<el-radio label="已确认非目标客户">已确认非目标客户</el-radio>
						<el-radio label="其他">其他</el-radio>
					</el-radio-group>
				</div>
				<div class="btn-box">
					<el-button size="small" @click="callPopup5Close">取消</el-button>
					<el-button size="small" @click="getblackListAdd">添加</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="isrepeatBox" width="450px" :show-close="false" :close-on-click-modal="false">
			<div>
				<div style="text-align: center;">
					<i class="el-icon-warning-outline" style="color: #F56C6C;font-size: 76px;margin-bottom: 35px;"></i>
					<div style="margin-left: 20px;text-align: left;font-size: 16px;">
						关闭去重功能，会出现同一个号码重复分配给不同员工，极有可能出现撞单和频繁骚扰情况，请谨慎使用！
					</div>
				</div>
				<div style="display: flex;flex-direction: row;justify-content: end;margin-top: 50px;">
					<el-button size="small" @click="quchong">去重</el-button>
					<el-button size="small" type="danger" @click="buquchong">不去重</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="filterBox" width="450px">
			<div class="filter-box">
				<div>
					<i class="el-icon-success" style="color: #40FF63;"></i>
					导入成功：{{ filterListData.success || 0 }}条
				</div>
				<div v-if="filterListData.format > 0" @click="seeError" class="icon-class">
					<i class="el-icon-error" style="color: #FFB640;"></i>
					格式错误：
					<span>{{ filterListData.format }}</span>
					条
				</div>
				<div v-else>
					<i class="el-icon-error" style="color: #FFB640;"></i>
					格式错误：0 条
				</div>
				<div v-if="filterListData.repeats > 0" @click="seeFilesRepeating" class="icon-class">
					<i class="el-icon-warning" style="color: #FF6D40;"></i>
					文件数据重复：
					<span>{{ filterListData.repeats }}</span>
					条
				</div>
				<div v-else>
					<i class="el-icon-warning" style="color: #FF6D40;"></i>
					文件数据重复：{{ 0 }}条
				</div>
				<div v-if="filterListData.filter > 0" @click="seeEnterpriseDuplication" class="icon-class">
					<i class="el-icon-info" style="color: #FF40D6;"></i>
					企业数据重复：
					<span>{{ filterListData.filter }}</span>
					条
				</div>
				<div v-else>
					<i class="el-icon-info" style="color: #FF40D6;"></i>
					企业数据重复：{{ 0 }}条
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="setIntervals" width="450px">
			<div class="call-type6">
				<div class="black-radio">
					<div class="title">间隔时间</div>
					<el-select size="small" v-model="setSeconds" @change="getsetIntervalsconfirm" filterable
						class="handle-select mr10" style="width: 180px">
						<el-option v-for="item in selector" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<div class="btn-box">
					<el-button size="small" @click="setIntervals = false">取消</el-button>
					<el-button size="small" @click="getsetIntervals">确认</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="errortab" width="450px">
			<div>
				<span class="tab-span" v-for="item in filterListData.formatList" :key="item">{{ item }}</span>
			</div>
		</el-dialog>
		<el-dialog v-model="filesRepeatingtab" width="450px">
			<div>
				<span class="tab-span" v-for="item in filterListData.repeatsList" :key="item">{{ item }}</span>
			</div>
		</el-dialog>
		<el-dialog v-model="enterpriseDuplicationtab" width="450px">
			<div>
				<span class="tab-span" v-for="item in filterListData.filterList" :key="item">{{ item }}</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		fetchData,
		addCustomer,
		delCustomer,
		fetchDataById,
		updateCustomer,
		batchDelete,
		customerFile,
		getByCustomerFileId,
		getCustomer,
		findByQuery,
		deleteFile,
		updateCustomerFile,
		countCustomer,
		socketSend,
		socketIntention,
		getDevices,
		getWebAddress,
		computerSpeed,
		blackListAdd,
		getblackListVerify,
		getrecordUpload,
		socketforced,
		deleteCompletedFile,
		getCallStatus,
		setIntervals,
		downloadCustomerTemplate,
		findNormal
	} from '../api/customerIndex';
	import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers, fetchCompany } from '../api/index.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import { addIntentionCustomer } from '../api/recordIndex';

	import md5 from '../api/md5.js'; //用于md5加密
	import axios from 'axios';
	const echarts = require('echarts');
	export default {
		name: 'baseTable',
		data() {
			return {
				editName: false, //修改线索的名字

				desensitization: true, //表格里的手机号是否隐藏  true是隐藏
				editNameForm: {
					fileName: '',
					customerFileId: ''
				},
				query: {
					status: -1,
					companyId: '',
					departmentId: '',
					userId: '',
					pageIndex: 1,
					pageSize: 100,
					name: '',
					blackListValue: false
				},
				menu: {
					pageIndex: 1,
					pageSize: 10
				},
				menupageTotal: 0,
				num: '',
				activeName: 'numberList', //默认显示的导航号码清单和线索统计clueEcharts  numberList
				query2: {
					pageIndex: 1,
					pageSize: 100,
					customerFileId: '',
					normalFileId: ''
				},
				menuList: [],
				defaultProps: {
					children: 'children',
					label: 'fileName'
				},
				fileList: [],
				agents: [],
				qyName: '',
				companys: [],
				departments: [],
				users: [],
				recordStatus: [{ label: '全部', value: -1 }, { label: '未拨打', value: 0 }, { label: '已拨打', value: 1 }],
				tableData: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				addVisible: false,
				addButton: false,
				userDis: false,
				updateButton: false,
				isNormal: true,

				isAdmin: false,
				isAgent: false,
				pageTotal: 0,
				form: {
					companyId: '',
					contactName: '',
					phoneNumber: '',
					userId: '',
					status: '未打',
					nameList: [],
					repeat: true
				},
				rules: {
					contactName: [{ required: true, message: '请输入客户姓名', trigger: 'blur' }],
					phoneNumber: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
					userId: [{ required: true, message: '请选择员工', trigger: 'change' }]
				},
				idx: -1,
				id: -1,
				show: false,
				upload: false,
				loading: false,
				companyDis: false,
				token: { Authorization: localStorage.getItem('token') },
				uploadData: {
					userId: localStorage.getItem('userId'),
					ids: '',
					repeat: true
				},
				path: process.env.BASE_URL,
				statisticsData: '',

				dialogIntention: false,
				formIntentionPhoneNumber: '',
				formIntention: {
					name: '',
					intention: 'C',
					remark: ''
				},
				optionsIntentionList: [
					{ label: '意向强', value: 'A' },
					{ label: '意向一般', value: 'B' },
					{ label: '无意向', value: 'C' },
					{ label: '需再跟进', value: 'D' },
					{ label: '无需跟进', value: 'E' }
				],
				blackremark: '其他',
				arrI: 0,
				bodadisabled: false,
				str2: [],
				str: [],
				zidongbohao: false,
				AppInfo: '',
				AppBtn: '关联APP',
				deviceName: null,
				wait_timer: 7,
				timer: 0,
				timer_interval: '',
				manualDialing: false, //手动拨号盘是否显示
				manualDialingTab: 'dial', //默认显示的导航号码清单和线索统计clueEcharts  numberList
				phoneNumberSearch: '', //搜索号码
				phone: '',
				callPopup5: false,
				speedsList: [], //手动拨打记录
				nameList: [], //可分配员工
				nameId: '', //已经分配员工id
				repeatText: '去  重',
				isrepeat: false,
				isrepeatBox: false,
				filterBox: false, //导入后的弹出
				filterListData: {},
				callDuration: 0,
				callStatus: false,
				setIntervals: false,
				setSeconds: localStorage.getItem('setSeconds'),
				selector: [
					{ label: '3秒', value: '3' },
					{ label: '4秒', value: '4' },
					{ label: '5秒', value: '5' },
					{ label: '6秒', value: '6' },
					{ label: '7秒', value: '7' },
					{ label: '8秒', value: '8' },
					{ label: '9秒', value: '9' },
					{ label: '10秒', value: '10' },
					{ label: '11秒', value: '11' },
					{ label: '12秒', value: '12' },
					{ label: '13秒', value: '13' },
					{ label: '14秒', value: '14' },
					{ label: '15秒', value: '15' },
					{ label: '16秒', value: '16' },
					{ label: '17秒', value: '17' },
					{ label: '无限制', value: '-1' }
				],
				errortab: false,
				filesRepeatingtab: false,
				enterpriseDuplicationtab: false
			};
		},

		created() {
			let role = localStorage.getItem('ms_role');
			if (role === 'normal') {
				this.isNormal = false;
			}
			if (role === 'admin') {
				this.isAdmin = true;
			}
			if (role === 'agent' || role === 'admin') {
				this.isAgent = true;
			}
			if (role == 'company') {
				this.isrepeat = true;
			}
			if (this.isAdmin) {
				this.init();
			} else if (this.isAgent) {
				this.init();
			} else {
				this.getcustomerFile();
				this.fetchDepartments('');
			}

			setInterval(() => {
				this.deviceName = localStorage.getItem('deviceName');
			}, 1000);
		},
		unmounted() {
			this.getshoudongbohao();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
					this.query.agentId = this.agents[0].value;
					this.fetchCompanyByAgentIds(this.query.agentId);
				});
				let data1 = {
					currentUserId: localStorage.getItem('user')
				};
				fetchDefaultCompanies(data1).then(res => {
					this.companys = res.data;
				});
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},
			toDateTimeStr(nS) {
				let date = new Date(parseInt(nS));
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD + ' ' + hh + mm + ss;
			},
			/* 查询部门 */
			fetchDepartments(id) {
				let data = {
					companyId: id,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
			},

			handleClick(tab, event) {
				if (tab.props.label == '线索统计') {
					this.countCustomers();
				} else {
					if (!this.myChart) {
						return;
					}
					this.myChart.dispose();
					this.myChart = null;
				}
			},
			handleManualDialingTab(tab, event) {
				if (tab.props.label == '拨号键盘') {
					console.log('拨号键盘');
				} else {
					this.computerSpeeds();
				}
			},
			/* 统计数据 */
			countCustomers() {
				// if(this.query2.customerFileId){

				// }
				let data = {
					customerFileId: this.query2.customerFileId,
					normalFileId: this.query2.normalFileId,
					currentUserId: localStorage.getItem('user')
				};
				countCustomer(data).then(res => {
					if (res.data[0]) {
						this.statisticsData = res.data[0];
						let oper_y = Object.values(res.data[1]);
						let oper_x = ['意向强', '意向一般', '无意向', '需再跟进', '无需跟进'];
						let chartdata = [];
						oper_x.forEach((one, index) => {
							let item = {};
							item.name = one;
							item.value = oper_y[index];
							chartdata.push(item);
						});
						this.myChart = echarts.init(document.getElementById('statisticsEcharts'));
						let option = {
							legend: {
								orient: 'vertical',
								x: '70%', //可设定图例在左、右、居中
								y: 'center',
								top: '10%', // bottom:"20%" // 组件离容器的距离
								right: '70%', //left:"10%"  // // 组件离容器的距离
								width: 'auto', // 图例组件的宽度
								height: 'auto',
								data: ['意向强', '意向一般', '无意向', '需再跟进', '无需跟进']
							},
							tooltip: {
								trigger: 'item',
								show: true,
								formatter: '{c}%'
							},

							series: [{
								type: 'pie',
								center: ['35%', '60%'],
								radius: ['35%', '55%'], //环形
								data: chartdata
							}]
						};
						this.myChart.setOption(option);
					}
				});
			},
			/* 搜索  */
			getcustomerFile() {
				this.loading = true;
				let data = {
					companyId: this.query.companyId,
					name: this.query.name,
					pageIndex: this.menu.pageIndex,
					pageSize: this.menu.pageSize,
					currentUserId: localStorage.getItem('user')
				};
				customerFile(data).then(res => {
					this.loading = false;
					if (res.data) {
						this.menuList = res.data.list;
						this.menupageTotal = res.data.total;
						if (this.menuList.length > 0) {
							this.menuList[0].flag = true;
							this.query2.customerFileId = this.menuList[0].id;
							this.getByCustomerFileIds(this.menuList[0].id);
							if (!this.myChart) {
								return;
							}
							this.myChart.dispose();
							this.myChart = null;
							this.countCustomers();
						}
					}
				});
			},

			getDeleteCompletedFile() {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						this.loading = true;
						let data = {
							userId: localStorage.getItem('user')
						};
						deleteCompletedFile(data).then(res => {
							this.loading = false;
							this.$message.success('删除成功');
							this.getcustomerFile();
						});
					})
					.catch(() => {});
			},
			/* 切换左侧导航的主节点 */
			curShow(item) {
				this.query2.normalFileId = '';
				this.menuList.forEach(i => {
					if (item == i) {
						i.flag = true;
					} else {
						i.flag = false;
					}
				});
				if (item.children) {
					this.query2.customerFileId = item.id;
					this.query.customerFileId = item.id;
					this.getByCustomerFileIds(item.id);
				} else {
					this.query2.normalFileId = item.id;
					this.query.normalFileId = item.id;
					this.getByCustomerFileIds(item.id);
				}
			},
			/* 获取左侧导航的主节点数据 */
			getByCustomerFileIds(id) {
				this.query2.customerFileId = id;
				this.tableData = [];
				this.num = '';
				let data = {
					customerFileId: this.query2.customerFileId,
					pageIndex: this.query2.pageIndex,
					pageSize: this.query2.pageSize,
					currentUserId: localStorage.getItem('user')
				};
				getByCustomerFileId(data).then(res => {
					this.tableData = res.data.customers;
					this.pageTotal = res.data.total;
					this.desensitization = res.data.desensitization;
					if (!this.myChart) {
						return;
					}
					this.myChart.dispose();
					this.myChart = null;
					this.countCustomers();
				});
			},
			/* 修改主节点名字 */
			getEdit(id, name) {
				this.editName = true;
				this.editNameForm.fileName = name;
				this.editNameForm.customerFileId = id;
			},
			getUpdateCustomerFile() {
				updateCustomerFile(this.editNameForm).then(res => {
					if (res.code === 200) {
						this.$message.success('修改成功');
						this.getcustomerFile();
					} else {
						this.$message.error(res.message);
					}
					this.editName = false;
				});
			},
			/* 删除主节点 */
			getDeleteCustomerFile(id) {
				let data = {
					id: id,
					currentUserId: localStorage.getItem('user')
				};
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						deleteFile(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getcustomerFile();
								this.getByCustomerFileIds(this.query2.customerFileId);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
				this.editName = false;
			},
			/* 切换左侧导航的子节点 */
			getCustomers(id) {
				this.tableData = [];
				this.query2.normalFileId = id;
				this.query.normalFileId = id;
				this.num = id;
				let data = {
					normalFileId: this.query2.normalFileId,
					pageIndex: this.query2.pageIndex,
					pageSize: this.query2.pageSize
				};
				getCustomer(data).then(res => {
					this.tableData = res.data.customers;
					this.pageTotal = res.data.total;
					if (!this.myChart) {
						return;
					}
					this.myChart.dispose();
					this.myChart = null;
					this.countCustomers();
				});
			},
			uploadExcel() {
				this.upload = true;
				let role = localStorage.getItem('ms_role');
				if (role === 'normal' || role === 'admin' || role === 'agent') {
					this.getfindNormal();
				}
			},
			getfindNormal() {
				// 获取可分配数据
				let data = {
					departmentId: this.query.departmentId
				};
				findNormal(data).then(res => {
					if (res.code == 200) {
						this.nameList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getdownloadCustomerTemplate() {
				if (this.isAgent) {
					// 下载模板
					let data = {
						companyId: this.query.companyId
					};
					downloadCustomerTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				} else {
					// 下载模板
					let data = {
						companyId: localStorage.getItem('company')
					};
					downloadCustomerTemplate(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '客户模板' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				}
			},
			handleCheckedCitiesChange(value) {
				this.uploadData.ids = value.toString();
			},
			getData() {
				this.query.currentUserId = localStorage.getItem('user');
				fetchData(this.query).then(res => {
					if (res.code === 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			getout() {
				let username = localStorage.getItem('ms_username');
				let data = {
					name: username,
					info: this.formIntention.intention,
					type: 4,
					remark: this.formIntention.remark,
					phoneNumber: this.phoneNumber,
					customerName: this.formIntention.name
				};
				socketIntention(data).then(res => {
					if (res.code == 500) {
						this.$message.error(res.message);
					} else if (res.code == 200) {
						if (res.data == true) {
							this.getByCustomerFileIds(this.query2.customerFileId);
							this.$message.success(res.message);
							this.dialogIntention = false;
							this.callDuration = 0;
							this.callStatus = false;
							if (this.timer) {
								clearInterval(this.timer);
							}
							if (this.setSecondstimer) {
								clearInterval(this.setSecondstimer);
								this.setSeconds = localStorage.getItem('setSeconds');
							}
							this.formIntention.intention = 'C';
							this.formIntention.remark = '';
							this.phoneNumber = '';
							this.arrI = 0;
						} else if (res.data == false) {
							this.$message.error('请挂断电话后操作');
							this.getByCustomerFileIds(this.query2.customerFileId);
						}
					}
				});
			},

			getWebAddress() {
				let data = {
					userName: localStorage.getItem('ms_username')
				};
				getWebAddress(data).then(res => {
					if (res.code == 500) {
						this.$message.error(res.message);
					} else if (res.code == 200) {
						localStorage.setItem('AppInfoIp', res.data.ip);
						let data = {
							userName: localStorage.getItem('ms_username')
						};
						getDevices(data).then(res => {
							if (res.code == 500) {
								this.$message.error(res.message);
							} else {
								this.AppInfo = res.data;
								localStorage.setItem('deviceName', this.AppInfo.deviceName);
								// window.location.reload();
								this.$router.push('/clue');
							}
						});
					}
				});
			},
			//自动拨打
			call() {
				this.zidongbohao = true;
				this.str = [];
				for (let i = 0; i < this.tableData.length; i++) {
					if (this.tableData[i].status == '未拨打') {
						this.str.push(this.tableData[i].phoneNumber);
						this.formIntention.customerName = this.tableData[i].contactName;
					}
				}
				if (this.fn(this.str).length > 0) {
					this.phoneNumber = this.fn(this.str)[this.arrI];
					this.autoDial(this.phoneNumber);
				} else {
					this.$message.error('请查看号码列表是否已拨打完');
					this.getByCustomerFileIds(this.query2.customerFileId);
				}
			},
			fn(arr) {
				return arr.filter((item, index, arr) => arr.indexOf(item) === index);
			},
			removeHyphensFromPhoneNumber(phoneNumber) {
				// 使用正则表达式匹配一个或多个连字符，并将其替换为空字符串
				return phoneNumber.replace(/-/g, '');
			},
			autoDial(phoneNumber) {
				if (phoneNumber) {
					let username = localStorage.getItem('ms_username');
					if (this.tableData.length > 0) {
						this.formIntention.name = this.tableData[this.arrI].contactName;
						this.formIntention.remark = this.tableData[this.arrI].remark;
					}
					let data = {
						name: username,
						info: this.removeHyphensFromPhoneNumber(phoneNumber),
						type: 3,
						customerName: this.formIntention.name,
						remark: this.formIntention.remark,
						protectionLevel: localStorage.getItem('protectionLevel').toString(),
						filtration: localStorage.getItem('filtration'),
					};
					this.bodadisabled = true;
					socketSend(data).then(res => {
						this.bodadisabled = false;
						if (res.code == 200) {
							if (res.data == 2) {
								this.dialogIntention = true;
								this.arrI = this.arrI + 1;
								this.timer = setInterval(() => {
									this.getCallStatus();
								}, 1000);
							} else if (res.data == 1) {
								this.$message.error('请APP重新进入电脑快拨页面');
								localStorage.removeItem('deviceName');
								this.manualDialing = false;
								setTimeout(() => {
									// window.location.reload();
									this.$router.push('/clue');
								}, 2000);
							} else if (res.data == 0) {
								let text = '此号码为“风险号码”，有历史投诉记录或当天接到多次营销电话，有极高投诉风险，禁止拨打！';
								let msg = phoneNumber + text;
								this.$message.error(msg);
								this.arrI = this.arrI + 1;
								this.call();
							} else if (res.data == 3) {
								let text = '号码已被拉入黑名单,不可拨打';
								let msg = phoneNumber + text;
								this.$message.error(msg);
								this.arrI = this.arrI + 1;
								this.call();
							}
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			autoDial2() {
				if (this.phoneNumber) {
					let username = localStorage.getItem('ms_username');
					let data = {
						name: username,
						info: this.removeHyphensFromPhoneNumber(this.phoneNumber),
						type: 3,
						remark: this.formIntention.remark,
						customerName: this.formIntention.name,
						protectionLevel: localStorage.getItem('protectionLevel').toString(),
						filtration: localStorage.getItem('filtration'),
					};
					this.bodadisabled = true;
					this.zidongbohao = false;
					socketSend(data).then(res => {
						this.bodadisabled = false;
						if (res.code == 200) {
							if (res.data == 2) {
								this.dialogIntention = true;
								this.timer = setInterval(() => {
									this.getCallStatus();
								}, 1000);
							} else if (res.data == 1) {
								this.$message.error('请APP重新进入电脑快拨页面');
								localStorage.removeItem('deviceName');
								this.manualDialing = false;
								setTimeout(() => {
									// window.location.reload();
									this.$router.push('/clue');
								}, 2000);
							} else if (res.data == 0) {
								let text = '此号码为“风险号码”，有历史投诉记录或当天接到多次营销电话，有极高投诉风险，禁止拨打！';
								let msg = this.phoneNumber + text;
								this.$message.error(msg);
							} else if (res.data == 3) {
								this.phoneNumber = '';
								let text = '号码已被拉入黑名单,不可拨打';
								let msg = this.phoneNumber + text;
								this.$message.error(msg);
							}
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			getCallStatus() {
				getCallStatus(localStorage.getItem('ms_username')).then(res => {
					if (res.code == 200) {
						this.callStatus = res.data.status;
						if (res.data.status == true) {
							this.callDuration = res.data.callDuration;
							if (this.zidongbohao) {
								this.setSecondstimer = setInterval(() => {
									if (this.setSeconds > 0) {
										this.setSeconds--;
									} else {
										let username = localStorage.getItem('ms_username');
										let data = {
											name: username,
											info: this.formIntention.intention,
											type: 4,
											remark: this.formIntention.remark,
											phoneNumber: this.phoneNumber,
											customerName: this.formIntention.name
										};
										socketIntention(data).then(res => {
											this.bodadisabled = false;
											if (res.code == 500) {
												this.$message.error(res.message);
											}
										});
										this.intention = 'C: 无意向';
										this.setSeconds = localStorage.getItem('setSeconds');
										this.callDuration = 0;
										this.callStatus = false;
										if (this.setSecondstimer) {
											clearInterval(this.setSecondstimer);
											this.setSeconds = localStorage.getItem('setSeconds');
										}
										this.formIntention.intention = 'C';
										this.formIntention.remark = '';

										if (this.arrI < this.tableData.length) {
											if (this.setSeconds > 0) {
												this.call();
												this.dialogIntention = false;
											}
										} else {
											this.dialogIntention = false;
											this.getByCustomerFileIds(this.query2.customerFileId);
										}
									}
								}, 1000);
							}
							if (this.timer) {
								clearInterval(this.timer);
							}
						} else {
							this.callDuration++;
						}
					}
				});
			},
			formatSeconds(result) {
				var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				var m = Math.floor((result / 60) % 60) < 10 ? '0' + Math.floor((result / 60) % 60) : Math.floor((result /
					60) % 60);
				var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
				if (h > 0) {
					return (result = h + ':' + m + ':' + s);
				} else {
					return (result = m + ':' + s);
				}
			},
			getSocketIntentions(e) {
				this.bodadisabled = true;
				let username = localStorage.getItem('ms_username');
				let data = {
					name: username,
					info: this.formIntention.intention,
					type: 4,
					remark: this.formIntention.remark,
					phoneNumber: this.phoneNumber,
					customerName: this.formIntention.name
				};
				socketIntention(data).then(res => {
					this.bodadisabled = false;
					if (res.code == 500) {
						this.$message.error(res.message);
					} else if (res.code == 200) {
						if (res.data == true) {
							if (e == 1) {
								let data = {
									customerName: this.formIntention.name,
									userId: localStorage.getItem('user'),
									phoneNumber: this.phoneNumber,
									remark: this.formIntention.remark,
									source: '手动添加',
									normalName: localStorage.getItem('user')
								};
								addIntentionCustomer(JSON.stringify(data)).then(res => {
									if (res.code == 200) {
										this.$message.success(res.message);

										this.callDuration = 0;
										this.callStatus = false;
										if (this.timer) {
											clearInterval(this.timer);
										}
										if (this.setSecondstimer) {
											clearInterval(this.setSecondstimer);
											this.setSeconds = localStorage.getItem('setSeconds');
										}
										this.formIntention.intention = 'C';
										this.formIntention.remark = '';
										this.dialogIntention = false;
										this.call();
									}
								});
							} else {
								this.$message.success(res.message);
								this.callDuration = 0;

								this.callStatus = false;
								if (this.timer) {
									clearInterval(this.timer);
								}
								if (this.setSecondstimer) {
									clearInterval(this.setSecondstimer);
									this.setSeconds = localStorage.getItem('setSeconds');
								}
								this.formIntention.intention = 'C';
								this.formIntention.remark = '';
								this.dialogIntention = false;
								this.call();
							}
						} else if (res.data == false) {
							this.$message.error('请挂断电话后操作');
							this.getByCustomerFileIds(this.query2.customerFileId);
						}
					}
				});
			},
			socketForced() {
				let data = {
					userId: localStorage.getItem('user'),
					userPhone: localStorage.getItem('ms_username'),
					phoneNumber: this.phoneNumber,
					intention: this.formIntention.intention,
					remark: this.formIntention.remark
				};
				socketforced(data).then(res => {
					this.getByCustomerFileIds(this.query2.customerFileId);
					this.$message.success(res.message);
					this.dialogIntention = false;
					this.callStatus = false;
					this.arrI = 0;
					if (this.timer) {
						clearInterval(this.timer);
					}
					if (this.setSecondstimer) {
						clearInterval(this.setSecondstimer);
						this.setSeconds = localStorage.getItem('setSeconds');
					}
					this.formIntention.intention = 'C';
					this.formIntention.remark = '';
					this.phoneNumber = '';
				});
			},
			/* 删除 */
			batchDel() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					let param = {
						customerIds: str,
						currentUserId: localStorage.getItem('user')
					};
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							batchDelete(param).then(res => {
								if (res.code == 200) {
									this.$message.success('批量删除成功');
									this.getByCustomerFileIds(this.query2.customerFileId);
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}
				this.multipleSelection = [];
			},

			/* 代理商 */
			agentChange(agentId) {
				this.fetchCompanyByAgentIds(agentId);
			},
			/* 切换代理商企业 */
			fileChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.fetchDepartments(companyId);
				this.getcustomerFile();
				// this.countCustomers();
			},
			/* 代理商下的企业 */
			fetchCompanyByAgentIds(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					fetchCompanyByAgentId(agentId).then(res => {
						this.companys = res.data;
						this.query.companyId = this.companys[0].value;
						this.fetchDepartments(this.query.companyId);
						this.getcustomerFile();
						// this.countCustomers();
					});
				}
			},
			/* 切换部门 */
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				this.query2.companyId = companyId;
				this.query2.departmentId = '';
				this.query2.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			departmentChange(departmentId) {
				let data = {
					departmentId: departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
				});
			},
			departmentChange2() {
				this.getfindNormal();
			},
			/* 我是右边搜索 */
			findByQuerys() {
				let data = {
					userId: this.query.userId,
					status: this.query.status,
					phone: this.query.phone,
					pageSize: this.query2.pageSize,
					pageIndex: this.query2.pageIndex,
					normalFileId: this.query2.normalFileId,
					customerFileId: this.query2.customerFileId,
					currentUserId: localStorage.getItem('user')
				};
				this.tableData = [];
				findByQuery(data).then(res => {
					this.tableData = res.data.customerVOS;
					this.pageTotal = res.data.total;
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.query2.pageIndex = 1;
				// this.getData();
				this.findByQuerys();
			},
			showForm() {
				this.addVisible = true;
				this.addButton = true;
				this.updateButton = false;
			},
			addCustomer(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						addCustomer(this.form).then(res => {
							if (res.code === 200) {
								this.$message.success('添加成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
						});
					}
				});
			},
			updateCustomer(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						updateCustomer(this.form).then(res => {
							if (res.code === 200) {
								this.$message.success(`修改第 ${this.idx + 1} 行成功`);
								this.getcustomerFile();
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
							this.loading = false;
						});
					}
				});
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						delCustomer(id).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
								this.getByCustomerFileIds(this.query2.customerFileId);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},

			getSocketIntention(e) {
				this.bodadisabled = true;
				let username = localStorage.getItem('ms_username');
				let data = {
					name: username,
					info: this.formIntention.intention,
					type: 4,
					remark: this.formIntention.remark,
					phoneNumber: this.phoneNumber,
					customerName: this.formIntention.name
				};
				socketIntention(data).then(res => {
					this.bodadisabled = false;
					if (res.code == 500) {
						this.$message.error(res.message);
					} else if (res.code == 200) {
						if (res.data == true) {
							if (e == 1) {
								this.getAddIntentionCustomer();
							} else {
								this.$message.success(res.message);
								this.dialogIntention = false;
								this.callDuration = 0;
								this.callStatus = false;
								if (this.timer) {
									clearInterval(this.timer);
								}
								this.formIntention.intention = 'C';
								this.formIntention.remark = '';

								this.phone = ''; //为了手动拨号后input清空
								this.phoneNumber = ''; //为了手动拨号后input清空
								this.getByCustomerFileIds(this.query2.customerFileId);
							}
						} else if (res.data == false) {
							this.$message.error('请挂断电话后操作');
							this.getByCustomerFileIds(this.query2.customerFileId);
						}
					}
				});
			},
			//转入crm
			getAddIntentionCustomer() {
				let data = {
					customerName: this.formIntention.name,
					userId: localStorage.getItem('user'),
					phoneNumber: this.phoneNumber,
					remark: this.formIntention.remark,
					source: '手动添加',
					normalName: localStorage.getItem('user')
				};
				addIntentionCustomer(JSON.stringify(data)).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.dialogIntention = false;
						this.callDuration = 0;
						this.callStatus = false;
						if (this.timer) {
							clearInterval(this.timer);
						}
						if (this.setSecondstimer) {
							clearInterval(this.setSecondstimer);
							this.setSeconds = localStorage.getItem('setSeconds');
						}

						this.formIntention.intention = 'C';
						this.formIntention.remark = '';
						this.phone = ''; //为了手动拨号后input清空
						this.phoneNumber = ''; //为了手动拨号后input清空
						this.getByCustomerFileIds(this.query2.customerFileId);
					} else {}
				});
			},
			handleSocketSend(row) {
				let username = localStorage.getItem('ms_username');
				this.formIntention.name = row.contactName;
				this.formIntention.remark = row.remark;
				let data = {
					name: username,
					info: this.removeHyphensFromPhoneNumber(row.phoneNumber),
					type: 3,
					customerName: this.formIntention.name,
					remark: this.formIntention.remark,
					protectionLevel: localStorage.getItem('protectionLevel').toString(),
					filtration: localStorage.getItem('filtration'),
				};
				this.bodadisabled = true;
				this.zidongbohao = false;
				socketSend(data).then(res => {
					if (res.code == 200) {
						this.bodadisabled = false;
						this.phoneNumber = row.phoneNumber;
						if (res.data == 2) {
							this.dialogIntention = true;
							// this.formIntentionPhoneNumber = a + row.phoneNumber + b;
							this.timer = setInterval(res => {
								this.getCallStatus();
							}, 1000);
						} else if (res.data == 1) {
							this.$message.error('请APP重新进入电脑快拨页面');
							localStorage.removeItem('deviceName');
							this.manualDialing = false;
							setTimeout(() => {
								// window.location.reload();
								this.$router.push('/clue');
							}, 2000);
						} else if (res.data == 0) {
							let text = '此号码为“风险号码”，有历史投诉记录或当天接到多次营销电话，有极高投诉风险，禁止拨打！';
							let msg = this.phoneNumber + text;
							this.$message.error(msg);
							// this.getByCustomerFileIds(this.query2.customerFileId);
						} else if (res.data == 3) {
							let text = '号码已被拉入黑名单,不可拨打';
							let msg = this.phoneNumber + text;
							this.$message.error(msg);
							// this.getByCustomerFileIds(this.query2.customerFileId);
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},

			// 编辑操作
			handleEdit(index, row) {
				let data = {
					departmentId: row.departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
				});
				this.addVisible = true;
				this.idx = index;
				this.addButton = false;
				this.userDis = true;
				this.companyDis = true;
				this.updateButton = true;
				// 获取详情
				fetchDataById(row.id).then(res => {
					this.form = res.data;
				});
			},

			// 分页导航
			handlePageChange(val) {
				this.query2.pageIndex = val;
				if (this.query2.normalFileId) {
					this.getCustomers(this.query2.normalFileId);
				} else {
					this.tableData = [];
					this.arrI = 0;
					this.getByCustomerFileIds(this.query2.customerFileId);
				}
			},
			handleSizeChange(val) {
				this.query2.pageSize = val;
				if (this.query2.normalFileId) {
					this.getCustomers(this.query2.normalFileId);
				} else {
					this.tableData = [];
					this.arrI = 0;
					this.getByCustomerFileIds(this.query2.customerFileId);
				}
			},
			// 分页导航
			handleCurrentChangemenu(val) {
				this.menu.pageIndex = val;
				this.getcustomerFile();
			},
			handleSizeChangemenu(val) {
				this.menu.pageSize = val;
				this.getcustomerFile();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.companyDis = false;
				this.userDis = false;
				(this.form = {
					companyId: '',
					contactName: '',
					phoneNumber: '',
					userId: '',
					status: '未打'
				}),
				this.resetForm(formName);
			},
			closeUpload() {
				this.$refs.upload.clearFiles();
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					if (this.uploadData.ids == '') {
						this.$message.error('请选择分配的员工');
					} else {
						this.loading = true;
						this.$refs.upload.submit();
					}
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			uploadSuccess(success) {
				setTimeout(() => {
					this.loading = false;
					this.$refs.upload.clearFiles();
					this.upload = false;
				}, 2000);
				if (success.code === 200) {
					this.$message.success('上传成功');
					// 	导入成功 35条   success
					//	文件数据重复  2条  repeats
					// 	格式错误 2条   format
					//  企业数据重复 2条  filter
					this.filterBox = true;
					this.filterListData = success.data;
					this.getcustomerFile();
				} else {
					this.open(success.message);
					// this.$message.error(success.message)
				}
			},
			open(message) {
				this.$alert(message, '导入失败', {
					confirmButtonText: '确定'
				});
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			getshoudongbohao() {
				this.phone = '';
				this.phoneNumber = '';
				this.manualDialing = true;
			},
			//按键拨打记录
			computerSpeeds() {
				let data = {
					userId: localStorage.getItem('user')
				};
				computerSpeed(data).then(res => {
					this.speedsList = res.data;
				});
			},
			//点击数字键
			addPhone(item) {
				if (isNaN(parseInt(item))) {
					this.phone = this.phone + item;
					this.phoneNumber = this.phone;
				} else {
					this.phone = this.phone + item;
					this.phoneNumber = this.phone;
				}
			},
			addPhone2(e) {
				this.phone = e;
				this.phoneNumber = e;
			},
			gtouchend() {
				this.phone = this.phone.slice(0, this.phone.length - 1);
				this.phoneNumber = this.phone;
			},

			callPopup5Close() {
				this.callPopup5 = false;
			},
			getblack() {
				this.blackremark = '其他';
				this.callPopup5 = true;
			},
			//黑名单选项
			blackRadioChange(evt) {
				this.blackremark = evt;
			},
			getblackListAdd() {
				let data = {
					userId: localStorage.getItem('user'),
					phoneNumber: this.phoneNumber,
					remark: this.blackremark
				};
				blackListAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.callPopup5 = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getrecordUploads(phoneNumber) {
				let data = {
					userId: localStorage.getItem('user'),
					userPhone: localStorage.getItem('ms_username'),
					phoneNumber: phoneNumber,
					contactName: this.formIntention.name
				};
				getrecordUpload(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.callPopup5 = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getswitch(e) {
				if (e == true) {
					this.isrepeatBox = false;
					this.repeatText = '去  重';
				} else {
					this.isrepeatBox = true;
					this.repeatText = '不去重';
				}
			},
			quchong() {
				this.repeatText = '去  重';
				this.isrepeatBox = false;
				this.uploadData.repeat = true;
				this.form.repeat = true;
			},
			buquchong() {
				this.isrepeatBox = false;
				this.repeatText = '不去重';
				this.uploadData.repeat = false;
				this.form.repeat = false;
			},
			setIntervalsBox() {
				this.setIntervals = true;
			},
			getsetIntervals() {
				let data = {
					userId: localStorage.getItem('user'),
					time: this.setSeconds
				};
				setIntervals(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						localStorage.setItem('setSeconds', this.setSeconds);
						this.setIntervals = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			seeError() {
				this.errortab = true;
			},
			seeFilesRepeating() {
				this.filesRepeatingtab = true;
			},
			seeEnterpriseDuplication() {
				this.enterpriseDuplicationtab = true;
			}
		}
	};
</script>
<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 14px;
		border-radius: 4px;
		margin-left: 10px;
		display: inline-block;
		width: 88px;
	}

	.container {
		border: none;
		padding: 3px 15px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.ml10 {
		margin-left: 10px;
	}

	.mt10 {
		margin-top: 10px;
	}

	.mb10 {
		margin-bottom: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}

	#my {
		width: 99%;
		background-color: #ffffff;
		overflow-y: auto;
	}

	.tree .item-menuList {
		margin: 10px;

		border: 1px solid #dcdee2;
	}

	.tree .item-menuList .title {
		display: block;
		padding: 10px;
		font-size: 14px;
		position: relative;
	}

	.title-name {
		display: inline-block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 70%;
		background: content-box;
		border: none;
		color: #515a6e;
	}

	.tree .p-item {
		width: 75%;
		margin-left: 40px;
	}

	.tree .p-item li {
		margin-bottom: 10px;
		font-size: 14px;
		position: relative;
		margin-top: 20px;
		border: 1px solid #dcdee2;
	}

	.tree .p-item .p-title {
		padding: 3px 0 0 10px;
		box-sizing: border-box;
		display: inline-block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 85%;
		display: block;
		cursor: pointer;
		color: #515a6e;
	}

	.tree .p-item .icon1 {
		display: inline-block;
		position: absolute;
		top: 28px;
		width: 25px;
		height: 2px;
		left: -30px;
		background-color: #ccc;
	}

	.tree .p-item .icon {
		display: inline-block;
		position: absolute;
		left: -30px;
		top: -15px;
		width: 2px;
		height: 45px;
		background-color: #ccc;
	}

	/* .tree li .title:hover {
	background-color: #6c6cf9;
	cursor: pointer;
	color: #fff;
} */
	.tree .title.active1 {
		/* background-color: #6c6cf9; */
		background-color: #e6f7ff;
		cursor: pointer;
		/* color: #fff; */
		border: 1px solid #2d8cf0;
	}

	.tree .p-item li:hover {
		/* background-color: #6c6cf9; */
		cursor: pointer;
		/* color: #fff; */
	}

	.tree .p-item li.blur {
		/* background-color: #6c6cf9;
	cursor: pointer;
	color: #fff; */

		background-color: #e6f7ff;
		cursor: pointer;
		border: 1px solid #2d8cf0;
	}

	.tree .p-item .el-progress {
		margin: 5px 10px !important;
		font-size: 12px !important;
	}

	.statistics-box .list {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #c2c2c2;
		padding-bottom: 16px;
	}

	.statistics-box .list .tit {
		color: rgba(0, 0, 0, 0.45);
		font-size: 12px;
		line-height: 22px;
		align-self: flex-start;
	}

	.statistics-box .list .num {
		color: rgba(0, 0, 0, 0.85);
		font-size: 24px;
		line-height: 38px;
		align-self: flex-start;
	}

	.dial-box {
		height: 450px;
		background: #ffffff;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.dial-box .dial_list {
		width: 60px;
		height: 60px;
		font-size: 32px;
		border-radius: 50%;
		color: #333333;
		line-height: 60px;
		text-align: center;
		overflow: hidden;
		margin-right: 20px;
		margin-left: 20px;
	}

	.search {
		outline: none;
		-webkit-appearance: none;
		background-color: #fff;
		background-image: none;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #606266;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		outline: 0;
		padding: 0 15px 0 34px;
		-webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		width: 100%;
	}

	.search:hover {
		border: 1px solid #c0c4cc;
	}

	.search:focus {
		border-color: #409eff;
		outline: 0;
	}

	.dial-box .input-box {
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: row;
	}

	.dial-box .input-box input {
		width: 98%;
		margin-top: 6px;
		outline: none;
		border: none;
		text-align: right;
		background-color: #ffffff;
	}

	.dial-box .input-box div {
		background-color: rgb(234, 245, 255);
		text-align: center;
		display: initial;
	}

	.dial-box .dial-btn {
		width: 100%;
		height: 40px;
		display: block;
		text-align: center;
	}

	.dial_list:active {
		color: #2c7ffb;
		background: #2c7ffb;
		background: rgba(44, 127, 251, 0.1);
	}

	.record-box {
		height: 400px;
		overflow-y: scroll;
		scrollbar-width: none;
		/* firefox */
		-ms-overflow-style: none;
		/* IE 10+ */
	}

	.record-box::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}

	.record-box li {
		border-bottom: 1px solid #ccc;
	}

	.record-box li .phone {
		font-size: 14px;
		margin-top: 10px;
	}

	.record-box li .info {
		display: flex;
		flex-direction: row;
		margin-top: 10px;
	}

	.record-box li .info .call-time {
		margin-left: 20px;
	}

	.record-box li .status-box {
		display: flex;
		flex-direction: row;
		font-size: 14px;
		margin-top: 10px;
	}

	.record-box li .status-box .call-status {
		width: 60px;
		height: 22px;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		margin-right: 16px;
		color: #fff;
		border-radius: 4px;
	}

	.record-box li .status-box .intention {
		width: 80px;
		height: 22px;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		margin-right: 16px;
		border-radius: 4px;
		color: #fff;
	}

	.record-box li .status-box .call-duration {
		width: 60px;
		height: 22px;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		margin-right: 16px;
		border-radius: 4px;
		background-color: #f3dec3;
		color: #cead82;
	}

	.record-box li .remark {
		font-size: 14px;
		margin: 10px 0;
	}

	.call-type5 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.call-type5 .input-box {
		height: 60px;
		border-bottom: 1px solid #ccc;
	}

	.call-type5 .black-radio {
		position: relative;
		top: -15px;
		border: 1px solid #007bfe;
		padding: 10px;
	}

	.call-type5 .black-radio .title {
		font-size: 16px;
		color: #000;
	}

	.call-type5 .black-radio .el-radio-group {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.call-type5 .black-radio .el-radio-group .el-radio {
		margin: 6px 2px;
	}

	.call-type5 .btn-box {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 30px;
	}

	.call-type5 .btn-box button {
		width: 84px;
		height: 34px;
		margin: 0;
		padding: 0;
		line-height: 34px;
		text-align: center;
		border-radius: 8px;
		color: #fff;
		font-size: 16px;
		background-color: #007bfe;
		border: none;
	}

	.filter-box div {
		display: flex;
		flex-direction: row;
		line-height: 50px;
		height: 50px;
	}

	::v-deep .el-radio__inner {
		border-radius: 2px;
	}

	::v-deep .el-radio__input.is-checked .el-radio__inner::after {
		content: '';
		width: 10px;
		height: 5px;
		border: 1px solid white;
		border-top: transparent;
		border-right: transparent;
		text-align: center;
		display: block;
		position: absolute;
		top: 2px;
		left: 0px;
		vertical-align: middle;
		transform: rotate(-45deg);
		border-radius: 0px;
		background: none;
	}

	.call-type6 .black-radio {
		display: flex;
		flex-direction: row;
	}

	.call-type6 .black-radio .title {
		font-size: 16px;
		color: #000;
		line-height: 40px;
		margin-right: 20px;
	}

	.call-type6 .black-radio .el-radio-group {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.call-type6 .black-radio .el-radio-group .el-radio {
		margin: 6px 2px;
	}

	.call-type6 .btn-box {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 30px;
	}

	.call-type6 .btn-box button {
		width: 84px;
		height: 34px;
		margin: 0;
		padding: 0;
		line-height: 34px;
		text-align: center;
		border-radius: 8px;
		color: #fff;
		font-size: 16px;
		background-color: #007bfe;
		border: none;
	}

	.tab-span {
		display: inline-block;
		display: flex;
		flex-direction: row;
		padding: 3px 6px;
		margin: 4px 6px;
	}

	.icon-class {
		cursor: pointer;
	}

	.icon-class span {
		color: #00ff00;
		font-weight: 600;
		text-decoration: underline;
	}

	.filter-box i {
		font-size: 30px;
		margin-right: 10px;
		line-height: 50px;
	}
</style>
<style>
	/* .tree li .title:hover .el-progress .el-progress__text {
	color: #fff !important;
} */
	/* .tree .title.active1 .el-progress .el-progress__text {
	color: #fff !important;
}
 */
	/* .tree li .title:hover .title-name {
	color: #fff !important;
} */
	/* .tree .title.active1 .title-name {
	color: #fff !important;
} */
	/* .tree .p-item li:hover .el-progress .el-progress__text {
	color: #fff !important;
} */
	/* .tree .p-item .blur .el-progress .el-progress__text {
	color: #fff !important;
} */
	.statistics-box .el-progress__text {
		display: flex;
		flex-direction: column;
	}

	.statistics-box .el-progress__text .percentage-label {
		color: rgba(0, 0, 0, 0.85);
		margin-top: 16px;
	}

	.clue .el-upload {
		width: 100% !important;
		height: 140px !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.clue .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}

	.el-progress-bar__inner {
		background-image: linear-gradient(to right, rgb(16, 142, 233) 0%, rgb(14, 237, 25) 100%) !important;
	}

	.el-dialog .el-dialog__body .dialog-title {
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		text-align: center !important;
		position: relative !important;
		top: -34px !important;
	}

	.el-dialog .el-dialog__body .dialog-title i {
		font-size: 50px !important;
		color: #007bfe !important;
	}

	.el-dialog .el-dialog__body .dialog-title .title {
		font-size: 22px;
		font-weight: 500;
		margin: 6px 0;
	}

	.el-dialog .el-dialog__body .dialog-title .time {
		font-weight: 500;
		font-size: 20px;
		color: rgb(16, 142, 233);
	}

	.input-box .el-input__inner {
		height: 50px !important;
		line-height: 50px !important;
		font-size: 24px !important;
		color: #333 !important;
	}
</style>